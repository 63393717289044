import { createRouter, createWebHistory } from 'vue-router';
import AdminHome from "@/views/admin/AdminHome.vue";
import CompanyView from "@/views/admin/CompanyView.vue";
import AdminLayout from "@/layout/AdminLayout.vue";
import UserLogin from "@/components/UserLogin.vue";
import AboutView from "@/views/admin/AboutView.vue";
import UserLayout from "@/layout/UserLayout.vue";
import UserProfile from "@/components/UserProfile.vue";
import UserHome from "@/views/user/UserHome.vue";
import UserEmployee from "@/views/user/EmployeeView.vue";
import WorkerView from "@/views/admin/WorkerView.vue";
import WorkdayView from "@/views/user/WorkdayView.vue";
import PaymentView from "@/views/user/PaymentView.vue";
import SettlementView from "@/views/user/SettlementView.vue";
import AccountView from "@/views/admin/AccountView.vue";

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: UserLogin },
  {
    path: '/admin',
    component: AdminLayout,
    meta: { requiresAuth: true, userType: 'admin' },
    children: [
      { path: 'home', name: 'adminHome', component: AdminHome },
      { path: 'company', name: 'companyView', component: CompanyView },
      { path: 'worker', name: 'workerView', component: WorkerView },
      { path: 'account', name: 'accountView', component: AccountView },
      { path: 'about', name: 'aboutView', component: AboutView },
    ],
  },
  {
    path: '/user',
    component: UserLayout,
    meta: { requiresAuth: true, userType: 'user' },
    children: [
      { path: 'home', name: 'userHome', component: UserHome },
      // { path: 'home', name: 'adminHome', component: AdminHome },
      { path: 'employee', name: 'UserEmployee', component: UserEmployee },
      { path: 'workday', name: 'workdayView', component: WorkdayView },
      { path: 'payment', name: 'paymentView', component: PaymentView },
      { path: 'settlement', name: 'settlementView', component: SettlementView },
    ],
  },
  { path: '/profile', name: 'UserProfile', component: UserProfile, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem('accessToken');
  const userType = localStorage.getItem('userType');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!accessToken) {
      next({ path: '/login' });
    } else {
      const requiredUserType = to.meta.userType;
      if (requiredUserType && userType !== requiredUserType) {
        next({ path: '/login' });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
