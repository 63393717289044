<template>
  <div>
    <!-- HeaderBar 中传入自定义左侧按钮，并隐藏右侧个人信息 -->
    <header-bar :showUserInfo="false">
      <template v-slot:left>
        <button @click="goBack" class="back-button">返回</button>
      </template>
      <template v-slot:title>
        <h1 class="header-title">账户设置</h1>
      </template>
    </header-bar>

    <!-- 个人信息内容部分 -->
    <div class="user-profile-container">
      <h3>基本资料</h3>
      <el-form label-width="80px">
        <el-form-item label="姓名">
          <el-input v-model="username" disabled></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model="realName" disabled></el-input>
        </el-form-item>
        <el-form-item label="单位名称">
          <el-input v-model="organization" disabled></el-input>
        </el-form-item>
        <el-form-item label="单位编号">
          <el-input v-model="oid" disabled></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'UserProfile',
  components: {
    HeaderBar,
  },
  computed: {
    ...mapState(['realName','oid','username', 'organization']), // 直接映射 realName 和 userType
  },
  methods: {
    ...mapActions(['loadUserInfo']),
    goBack() {
      this.$router.go(-1);
    },
  },
  created() {
    this.loadUserInfo(); // 组件创建时加载用户信息
  },
};
</script>

<style scoped>
.user-profile-container {
  max-width: 500px;
  margin: 20px auto;
}

.back-button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.header-title {
  color: white;
  font-size: 20px;
  margin: 0;
  text-align: center;
}
</style>
