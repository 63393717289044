// src/configs/companyViewConfig.js

import {formatDate, formatDateTime} from '@/utils/dateUtil';
import { ElMessage } from 'element-plus';

export const companyViewConfig = {
    buttons: [
        {
            text: '新增',
            type: 'primary',
            handler: (context) => {
                context.formData = {};
                context.dialogVisible = true;
            }
        },
        {
            text: '编辑',
            type: 'primary',
            handler: (context) => {
                if (context.selectedRows.length === 1) {
                    context.formData = { ...context.selectedRows[0] }; // 复制选中的记录到表单
                    context.dialogVisible = true; // 打开编辑对话框
                } else {
                    ElMessage.warning('请选择一条记录进行编辑');
                }
            },
            disabled: (context) => context.selectedRows.length !== 1 // 只有选择一条记录时可用
        },
        {
            text: '删除',
            type: 'danger',
            handler: async (context) => {
                if (context.selectedRows.length === 0) {
                    ElMessage.warning('请选择要删除的记录');
                    return;
                }
                const ids = context.selectedRows.map(row => row.id); // 获取选中行的 ID
                await context.handleDelete(ids); // 调用新增的删除方法
            },
            disabled: (context) => context.selectedRows.length === 0 // 只有选择记录时可用
        },
        {
            text: '导入',
            type: 'primary',
            handler: async (context) => {
                const  fieldMapping=     {
                    '单位编号': 'memberId',
                    '单位名称': 'companyName',
                    '会员类型': 'memberType',
                    '负责人': 'major',
                    '介绍网址': 'url',
                    '主营业务': 'mainBussiness',
                }

                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = '.xlsx, .xls'; // 只接受 Excel 文件
                fileInput.onchange = async (event) => {
                    const file = event.target.files[0];
                      context.handleImport(file, fieldMapping); // 传递 fieldMapping
                };
                fileInput.click(); // 触发文件选择对话框
            }
        },

        {
            text: '导出',
            type: 'success',
            handler: async (context) => {
            const  fieldMapping=     {
                    '单位编号': 'memberId',
                    '单位名称': 'companyName',
                    '会员类型': 'memberType',
                    '负责人': 'major',
                    '介绍网址': 'url',
                    '主营业务': 'mainBussiness',
                }
                    context.handleExport(fieldMapping); // 调用通用的删除方法
            }
        },


        {
            text: '刷新',
            type: 'primary',
            handler: (context) => {
                context.fetchData(); // 刷新数据
            }
        }
    ],
    columns: [
        { prop: 'companyName', label: '公司名称', width: '260' },
        { prop: 'memberId', label: '编号', width: '60' },
        { prop: 'memberType', label: '会员类型', width: '100' },
        { prop: 'major', label: '负责人', width: '80' },
        { prop: 'mainBussiness', label: '主营业务', width: '400' },
        {
            prop: 'createTime',
            label: '创建时间',
            width: '180',
            formatter: (row) => {
                return formatDateTime(row.createTime);
            }
        },
        {
            prop: 'reviseTime',
            label: '修改时间',
            width: '180',
            formatter: (row) => {
                return formatDateTime(row.reviseTime);
            }
        }
    ],
    selectable: true,
    searchPlaceholder: '请输入公司名称|负责人',
    dialog: {
        title: '编辑公司信息',
        width: '50%',
        fields: [
            { prop: 'companyName', label: '公司名称', type: 'input', required: true },
            { prop: 'memberId', label: '编号', type: 'input', disabled: false },
            {
                prop: 'memberType',
                label: '会员类型',
                type: 'select',
                options: [
                    { value: '1', label: '会员' },
                    { value: '2', label: '副会长' },
                    { value: '3', label: '会长' },
                    { value: '4', label: '理事' },
                    { value: '5', label: '监事长' }
                ],
                required: true
            },

            { prop: 'major', label: '负责人', type: 'input', required: true },
            { prop: 'url', label: '介绍网址', type: 'input', span: 24,required: true },
            {
                prop: 'mainBussiness',
                label: '主营业务',
                type: 'textarea',
                span: 24,
                rows: 5 // 自定义行数
            },

        ]
    },
    rules: {
        companyName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
        ],
        memberType: [
            { required: true, message: '请选择会员类型', trigger: 'change' }
        ],
        major: [
            { required: true, message: '请输入负责人', trigger: 'blur' }
        ]
    },
    // tableActions: {
    //     width: 150,
    //     buttons: [
    //         {
    //             text: '编辑',
    //             type: 'primary',
    //             size: 'small',
    //             handler: (row, context) => {
    //                 context.formData = { ...row };
    //                 context.dialogVisible = true;
    //             }
    //         },
    //         {
    //             text: '删除',
    //             type: 'danger',
    //             size: 'small',
    //             handler: (row, context) => {
    //                 context.handleDelete(); // 调用通用的删除方法
    //             }
    //         },
    //     ]
    // },
    // customMethods: {
    //     beforeSubmit: (formData) => {
    //         // 在提交前对表单数据进行处理
    //         formData.updateTime = new Date().toISOString();
    //         return formData;
    //     },
    //     afterFetch: (data) => {
    //         // 在获取数据后对数据进行处理
    //         return data.map(item => ({
    //             ...item,
    //             statusText: item.status === 1 ? '有效' : '无效'
    //         }));
    //     }
    // }
};

export default companyViewConfig;
