// src/configs/workdayViewConfig.js

import {formatDate, formatDateTime} from '@/utils/dateUtil';
import { ElMessage } from 'element-plus';

export const workdayViewConfig = {
    buttons: [
        {
            text: '新增',
            type: 'primary',
            handler: (context) => {
                context.formData = {};
                context.dialogVisible = true;
            }
        },
        {
            text: '编辑',
            type: 'primary',
            handler: (context) => {
                if (context.selectedRows.length === 1) {
                    context.formData = { ...context.selectedRows[0] }; // 复制选中的记录到表单
                    context.dialogVisible = true; // 打开编辑对话框
                } else {
                    ElMessage.warning('请选择一条记录进行编辑');
                }
            },
            disabled: (context) => context.selectedRows.length !== 1 // 只有选择一条记录时可用
        },
        {
            text: '删除',
            type: 'danger',
            handler: async (context) => {
                if (context.selectedRows.length === 0) {
                    ElMessage.warning('请选择要删除的记录');
                    return;
                }
                const ids = context.selectedRows.map(row => row.id); // 获取选中行的 ID
                await context.handleDelete(ids); // 调用新增的删除方法
            },
            disabled: (context) => context.selectedRows.length === 0 // 只有选择记录时可用
        },
        {
            text: '导入',
            type: 'primary',
            handler: async (context) => {
                const  fieldMapping=     {
                    '工号': 'emid',
                    '姓名': 'name',
                    '班组': 'teamgroupName',
                    '项目名称': 'projectName',
                    '考勤日期': 'workDate',
                    '包工金额': 'baogong',
                    '点工数目': 'diangong',
                    '工作内容': 'content',
                    '备注': 'description',
                }

                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = '.xlsx, .xls'; // 只接受 Excel 文件
                fileInput.onchange = async (event) => {
                    const file = event.target.files[0];
                      context.handleImport(file, fieldMapping); // 传递 fieldMapping
                };
                fileInput.click(); // 触发文件选择对话框
            }
        },

        {
            text: '导出',
            type: 'success',
            handler: async (context) => {
            const  fieldMapping=     {
                    '工号': 'emid',
                    '姓名': 'name',
                    '班组': 'teamgroupName',
                    '项目名称': 'projectName',
                    '考勤日期': 'workDate',
                    '包工金额': 'baogong',
                    '点工数目': 'diangong',
                    '工作内容': 'content',
                    '备注': 'description',
                }
                    context.handleExport("考勤表",fieldMapping); // 调用通用的删除方法
            }
        },


        {
            text: '刷新',
            type: 'primary',
            handler: (context) => {
                context.fetchData(); // 刷新数据
            }
        }
    ],
    columns: [
        { prop: 'emid', label: '工号', width: '80' },
        { prop: 'name', label: '姓名', width: '80' },
        { prop: 'teamgroupName', label: '班组', width: '120' },
        { prop: 'projectName', label: '项目', width: '160' },
        { prop: 'workDate', label: '考勤日期', width: '120' ,
            formatter: (row) => {
                return formatDate(row.workDate);
            }
        },
        { prop: 'baogong', label: '包工金额', width: '120' },
        { prop: 'diangong', label: '点工数目', width: '120' },

        { prop: 'content', label: '工作内容', width: '300' },
        { prop: 'description', label: '备注', width: '300' },
        {
            prop: 'createTime',
            label: '创建时间',
            width: '170',
            formatter: (row) => {
                return formatDateTime(row.createTime);
            }
        },
        {
            prop: 'reviseTime',
            label: '修改时间',
            width: '170',
            formatter: (row) => {
                return formatDateTime(row.reviseTime);
            }
        }
    ],
    selectable: true,
    searchPlaceholder: '姓名|班组',
    dialog: {
        title: '编辑',
        width: '50%',
        fields: [
            { prop: 'emid', label: '工号', type: 'input',span: 12, required: true },
            { prop: 'name', label: '姓名', type: 'input',span: 12, disabled: false },
            { prop: 'teamgroupName', label: '班组', type: 'input',span: 12, disabled: false },
            { prop: 'projectName', label: '项目', type: 'input',span: 12, disabled: false },
            { prop: 'workDate', label: '考勤日期', type: 'date', span: 12, required: true }, // Ensure this is a date type
            { prop: 'baogong', label: '包工金额', type: 'input',span: 12,required: true },
            { prop: 'diangong', label: '点工数目', type: 'input',span: 12, required: true },
            { prop: 'content', label: '工作内容', type: 'input', span: 24,required: true },
            { prop: 'description', label: '备注', type: 'textarea', span: 24,required: true },

        ]
    },
    rules: {
        workdayName: [
            { required: true, message: '请输入员工姓名', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
        ],
        memberType: [
            { required: true, message: '请选择会员类型', trigger: 'change' }
        ],
        major: [
            { required: true, message: '请输入负责人', trigger: 'blur' }
        ]
    },
};

export default workdayViewConfig;
