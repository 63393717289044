// src/configs/accountViewConfig.js

import {formatDate, formatDateTime} from '@/utils/dateUtil';
import { ElMessage } from 'element-plus';

export const accountViewConfig = {
    buttons: [
        {
            text: '新增',
            type: 'primary',
            handler: (context) => {
                context.formData = {};
                context.dialogVisible = true;
            }
        },
        {
            text: '编辑',
            type: 'primary',
            handler: (context) => {
                if (context.selectedRows.length === 1) {
                    context.formData = { ...context.selectedRows[0] }; // 复制选中的记录到表单
                    context.dialogVisible = true; // 打开编辑对话框
                } else {
                    ElMessage.warning('请选择一条记录进行编辑');
                }
            },
            disabled: (context) => context.selectedRows.length !== 1 // 只有选择一条记录时可用
        },
        {
            text: '删除',
            type: 'danger',
            handler: async (context) => {
                if (context.selectedRows.length === 0) {
                    ElMessage.warning('请选择要删除的记录');
                    return;
                }
                const ids = context.selectedRows.map(row => row.id); // 获取选中行的 ID
                await context.handleDelete(ids); // 调用新增的删除方法
            },
            disabled: (context) => context.selectedRows.length === 0 // 只有选择记录时可用
        },

        {
            text: '刷新',
            type: 'primary',
            handler: (context) => {
                context.fetchData(); // 刷新数据
            }
        }
    ],
    columns: [
        { prop: 'organization', label: '单位名称', width: '180' },
        { prop: 'oid', label: '单位编号', width: '100' },
        { prop: 'username', label: '账号', width: '120' },
        { prop: 'realName', label: '姓名', width: '120' },
        {
            prop: 'createTime',
            label: '创建时间',
            width: '180',
            formatter: (row) => {
                return formatDateTime(row.createTime);
            }
        },
        {
            prop: 'reviseTime',
            label: '修改时间',
            width: '180',
            formatter: (row) => {
                return formatDateTime(row.reviseTime);
            }
        }
    ],
    selectable: true,
    searchPlaceholder: '请输入公司名称|负责人',
    dialog: {
        title: '编辑账号信息',
        width: '40%',
        fields: [
            { prop: 'organization', label: '单位名称', type: 'input', required: true,span:24 },
            { prop: 'oid', label: '单位编号', type: 'input', disabled: false,span:24  },
            { prop: 'username', label: '登录账号', type: 'input', required: true ,span:24},
            { prop: 'realName', label: '中文名称', type: 'input', disabled: false ,span:24},

        ]
    },
    rules: {
        organization: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
        ],

    },
    tableActions: {
        columnName: '操作',
        width: '200',
        buttons: [
            // {
            //     text: '编辑',
            //     type: 'primary',
            //     size: 'small',
            //     componentType: 'button',
            //     handler: (row, context) => {
            //         // 处理编辑操作
            //     },
            // },
            // {
            //     text: '删除',
            //     type: 'danger',
            //     size: 'small',
            //     componentType: 'button',
            //     handler: (row, context) => {
            //         // 处理删除操作
            //     },
            // },
            {
                prop: 'enabled',  // 绑定的字段
                componentType: 'switch',
                activeText: '禁用',
                inactiveText: '启用',
                handler: (row, context) => {
                    // 处理开关状态改变
                    console.log(row,context);
                },
            },
        ],
    },
};

export default accountViewConfig;
