// useBaseDataOperations.js
import { ref, computed } from 'vue';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import * as XLSX from "xlsx";

export function useBaseDataOperations(config, service) {
    const store = useStore();
    // const oid = store.state.oid;
    const oid=localStorage.getItem("oid");
    if (!oid) {
        console.error('OID is not defined');
        ElMessage.error('OID 未定义');
        return; // 退出函数，避免后续操作
    }

    const tableData = ref([]);
    const searchKeyword = ref('');
    const selectedRows = ref([]);
    const formData = ref({});
    const dialogVisible = ref(false);

    const fetchData = async () => {
        try {
            const res = await service.fetchData(oid);
            tableData.value = res.data;
        } catch (err) {
            console.error('Fetching data failed:', err);
            ElMessage.error('获取数据失败');
        }
    };

    const handleDelete = async (ids) => {
        try {
            await service.deleteData(oid,ids); // 调用后端删除接口
            ElMessage.success('删除成功');
            tableData.value = tableData.value.filter(item => !ids.includes(item.id));

        } catch (error) {
            ElMessage.error('删除失败');
        }
    };



    const handleExport = async (filename,fieldMapping) => {
        try {
            // 使用 fieldMapping 生成导出数据
            const data = tableData.value.map(item => {
                return Object.keys(fieldMapping).reduce((acc, key) => {
                    acc[key] = item[fieldMapping[key]]; // 根据 fieldMapping 映射每个 item 的属性
                    return acc;
                }, {});
            });

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, '单位列表');
            XLSX.writeFile(workbook, filename+'.xlsx');
            ElMessage.success('导出成功');
        } catch (error) {
            ElMessage.error('导出失败');
        }
    };


    const handleSearch = async () => {
        try {
            const res = await service.searchData(oid, searchKeyword.value);
            tableData.value = res.data;
        } catch (err) {
            console.error('Searching failed:', err);
            ElMessage.error('搜索失败');
        }
    };

    const handleSelectionChange = (val) => {
        selectedRows.value = val;
    };



    const handleSizeChange = (size) => {
        fetchData(oid);
    };

    const handleDialogClose = () => {
        dialogVisible.value = false;
        formData.value = {};
    };

    const handleDialogConfirm = async () => {
        try {
            if (formData.value.id) {
                await service.updateData(oid, formData.value);
                ElMessage.success('更新成功');
            } else {
                await service.addData(oid, formData.value);
                ElMessage.success('添加成功');
            }
            dialogVisible.value = false;
            fetchData();
        } catch (err) {
            console.error('Operation failed:', err);
            dialogVisible.value = true;
            ElMessage.error('操作失败');
        }
    };


    const handleImport = async (file, fieldMapping) => {
        try {
            const rawData = await readExcelFile(file); // 读取 Excel 文件
            console.log('原始数据:', rawData); // 打印原始数据

            // 获取表头
            const headers = rawData[0];

            // 转换数据，跳过第一行（表头）
            const transformedData = rawData.slice(1).map(row => {
                const transformedRow = {};
                Object.keys(fieldMapping).forEach(key => {
                    const columnIndex = headers.indexOf(key); // 获取表头中对应列的索引
                    if (columnIndex !== -1) {
                        transformedRow[fieldMapping[key]] = row[columnIndex]; // 使用索引进行转换
                    }
                });
                return transformedRow;
            });

            console.log('转换后的数据:', transformedData); // 打印转换后的数据

            const response = await service.importData(oid, transformedData); // 调用服务进行数据导入
            if (response && response.data) {
                tableData.value = response.data; // 更新表格数据为导入返回的数据
            } else {
                ElMessage.error('导入失败，未返回有效数据');
            }
            return true; // 返回成功标志
        } catch (error) {
            console.error('导入失败:', error);
            ElMessage.error('导入失败');
            return false; // 返回失败标志
        }
    };

// 辅助函数：读取 Excel 文件
    const readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // 以数组形式读取数据
                resolve(jsonData); // 返回读取的数据
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsArrayBuffer(file); // 读取文件
        });
    };

    const tableRowClassName = ({ row }) => {
        return selectedRows.value.some(selectedRow => selectedRow.id === row.id) ? 'selected-row' : '';
    };



    return {
        tableData,
        searchKeyword,
        selectedRows,
        formData,
        dialogVisible,
        fetchData,
        handleDelete,
        handleImport,
        handleExport,
        handleSearch,
        handleSelectionChange,
        handleSizeChange,
        handleDialogClose,
        handleDialogConfirm,
        tableRowClassName
    };
}
