<template>
  <div class="UserHome">
    <div class="stats">
      <div class="stat" v-for="(value, label) in stats" :key="label">
        <h3>{{ label }}</h3>
        <p>{{ value }}</p>
      </div>
    </div>
    <div class="chart-wrapper">
      <LineChart :chartData="chartData" :options="chartOptions"></LineChart>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import LineChart from '../../components/LineChart.vue';
import userHomeService from "@/api/userHomeService";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";

export default {
  name: 'UserHome',
  components: {
    LineChart,
  },
  setup() {

    const store = useStore();
    // const oid = store.state.oid;
    const oid=localStorage.getItem("oid");
    if (!oid) {
      console.error('OID is not defined');
      ElMessage.error('OID 未定义');
      return; // 退出函数，避免后续操作
    }
    const stats = ref({
      '工人总数': 0,
      '在职人数': 0,
      '预警人数': 0,
      '黑名单': 0,
    });

    const chartData = ref({
      labels: ['20岁以下', '20~30岁', '30~40岁', '40~50岁', '50~60岁', '60岁以上'],
      datasets: [
        {
          label: '年龄分布统计',
          backgroundColor: 'rgba(75,192,192,0.2)',
          borderColor: '',
          pointBackgroundColor: 'rgba(255,165,0,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(255,165,0,1)',
          data: [],
          borderWidth: 3,
          pointRadius: 6,
          pointHoverRadius: 8,
          tension: 0.5, // 添加曲线平滑效果
        },
      ],
    });

    const chartOptions = ref({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: '年龄段',
          },
        },
        y: {
          display: true,
          title: {
            display: true,
            text: '人数',
          },
          beginAtZero: true,
        },
      },
    });

    const fetchStats = async () => {
      try {
        const response = await userHomeService.getHomeStats(oid);
        const data = response.data;
        stats.value['工人总数'] = data['total'];
        stats.value['在职人数'] = data['onjobNumber'];
        stats.value['预警人数'] = data['warnNumber'];
        stats.value['黑名单'] = data['blacklistNumber'];
      } catch (error) {
        console.error('Error fetching user stats:', error);
      }
    };

    const fetchAgeDistribution = async () => {
      try {
        const response = await userHomeService.getChartData(oid);
        console.log('获取到的数据:', response.data); // 添加日志查看数据

        // 确保 chartData 的初始结构正确
        chartData.value = {
          labels: ['20岁以下', '20~30岁', '30~40岁', '40~50岁', '50~60岁', '60岁以上'],
          datasets: [
            {
              label: '年龄分布统计',
              backgroundColor: 'rgba(75,192,192,0.2)',
              data: response.data, // 直接使用返回的数组数据
              borderWidth: 3,
              pointRadius: 6,
              pointHoverRadius: 8,
              tension: 0.5,
            }
          ]
        };

        // 设置渐变色
        const ctx = document.createElement('canvas').getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 100);
        gradient.addColorStop(0, 'rgba(108, 255, 208, 1)');
        gradient.addColorStop(1, 'rgba(112, 154, 255, 1)');
        chartData.value.datasets[0].borderColor = gradient;

      } catch (error) {
        console.error('Error fetching age distribution:', error);
      }
    };

    onMounted(async () => {
      await fetchStats();
      await fetchAgeDistribution();
    });

    return {
      stats,
      chartData,
      chartOptions,
    };
  },
};
</script>

<style scoped>
.UserHome {
  padding: 20px;
}

.stats {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;
}

.stat {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 23%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease; /* 添加悬停动画效果 */
}

.stat:hover {
  transform: translateY(-5px); /* 悬停时略微上移 */
}

.stat h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
}

.stat p {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.chart-wrapper {
  margin-top: 30px;
  height: 350px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
