import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import '@/assets/css/global.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import api, { loadApiModules } from './api/api';

const app = createApp(App);

loadApiModules().then(() => {
    app.config.globalProperties.$api = api;

    app.use(store)
        .use(router)
        .use(ElementPlus, {
            locale: zhCn,
        }).mount('#app');
}).catch(error => {
    console.error("Failed to load API modules:", error);
});
