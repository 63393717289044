// payment.js
import request from '@/utils/request';
import store from '@/store'; // 引入 store

export default {
    addData(oid, data) {
        return request({
            url: '/payment/add',
            method: 'post',
            params: { oid },
            data
        });
    },

    updateData(oid, data) {
        return request({
            url: '/payment/update',
            method: 'post',
            params: { oid },
            data
        });
    },



    deleteData(oid, ids) {
        return request({
            url: '/payment/delete',
            method: 'delete',
            params: { oid },
            data: ids, // `ids` 作为请求体发送
        });
    },

    importData(oid, data) {
        return request({
            url: '/payment/import',
            method: 'post',
            params: { oid },
            data
        });
    },

    fetchData(oid) {
        return request({
            url: '/payment/findRecentChangedData',
            method: 'get',
            params: { oid } // 确保参数名称与后端接口匹配
        });
    },
    searchData(oid, keyword) {
        return request({
            url: '/payment/search',
            method: 'get',
            params: { oid, keyword }
        });
    }
};
