// utils/request.js

import axios from 'axios';
import store from "@/store";

const BASE_URL = '/api';
// const BASE_URL = 'http://localhost:8000';
// const BASE_URL = 'https://www.hblaoxie.com/api';
const request = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// 请求拦截器
request.interceptors.request.use(config => {
    const accessToken = store.state.accessToken; // 从Vuex store中获取accessToken
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

// 刷新 token 的函数
const refreshToken = async () => {
    const refreshToken = store.state.refreshToken; // 从Vuex store中获取refreshToken
    if (!refreshToken) {
        handleTokenError();
        return null;
    }

    try {
        const response = await axios.post(`${BASE_URL}/auth/refresh-token`, { token: refreshToken }, {
            headers: { 'Content-Type': 'application/json' }
        });

        if (response.data.success) {
            const { accessToken } = response.data.data;
            await store.dispatch('login', {accessToken, refreshToken}); // 更新Vuex store中的accessToken
            return accessToken;
        }
    } catch (error) {
        console.error('Refresh token error:', error);
        handleTokenError();
    }
    return null;
};

// 处理 token 错误
const handleTokenError = () => {
    // sessionStorage.removeItem('accessToken');
    // sessionStorage.removeItem('refreshToken');
    // sessionStorage.removeItem('realName');
    store.dispatch('logout'); // 清除Vuex store中的token数据
    window.location.href = '/login';
};

// 响应拦截器
request.interceptors.response.use(response => {
    console.log("响应拦截器response:",response)
    if (response.data.success) {
        return response.data; // 直接返回数据对象
    } else {
        return Promise.reject(response.data); // 失败进入.catch()
    }
}, async error => {
    console.log("响应拦截器error:",error)
    const originalRequest = error.config;

    if (error.response) {
        const { status } = error.response;
        if (status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newAccessToken = await refreshToken();
            if (newAccessToken) {
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return request(originalRequest); // 重新发送原始请求
            }
        } else if (status === 403) {
            handleTokenError(); // 处理403错误
        }
    }
    return Promise.reject(error); // 其他错误处理
});

export default request;
