// src/configs/workerViewConfig.js

import {formatDate, formatDateTime} from '@/utils/dateUtil';

export const workerViewConfig = {
    buttons: [
        {
            text: '刷新',
            type: 'primary',
            handler: (context) => {
                context.fetchData(); // 刷新数据
            }
        }
    ],
    columns: [
        { prop: 'name', label: '姓名' },
        { prop: 'mobile', label: '手机号' },
        { prop: 'idcard', label: '身份证号',width: '200'},
        { prop: 'address', label: '住址' ,width: '400'},
        {
            prop: 'createTime',
            label: '创建时间',
            width: '180',
            formatter: (row) => {
                return formatDateTime(row.createTime);
            }
        },
        {
            prop: 'reviseTime',
            label: '修改时间',
            width: '180',
            formatter: (row) => {
                return formatDateTime(row.reviseTime);
            }
        }
    ],
    selectable: true,
    searchPlaceholder: '请输入姓名|手机 号',
    dialog: {
        title: '编辑员工信息',
        width: '50%',
        fields: [
            { prop: 'workerName', label: '公司名称', type: 'input', required: true },
            { prop: 'memberId', label: '编号', type: 'input', disabled: false },
            {
                prop: 'memberType',
                label: '会员类型',
                type: 'select',
                options: [
                    { value: '1', label: '会员' },
                    { value: '2', label: '副会长' },
                    { value: '3', label: '会长' },
                    { value: '4', label: '理事' },
                    { value: '5', label: '监事长' }
                ],
                required: true
            },

            { prop: 'major', label: '负责人', type: 'input', required: true },
            { prop: 'url', label: '介绍网址', type: 'input', span: 24,required: true },
            {
                prop: 'mainBussiness',
                label: '主营业务',
                type: 'textarea',
                span: 24,
                rows: 5 // 自定义行数
            },

        ]
    },
    rules: {
        workerName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' },
            { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
        ],
        memberType: [
            { required: true, message: '请选择会员类型', trigger: 'change' }
        ],
        major: [
            { required: true, message: '请输入负责人', trigger: 'blur' }
        ]
    },


};

export default workerViewConfig;
