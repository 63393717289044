<!-- BaseDataView.vue -->
<template>
  <div class="base-data-view">
    <div class="button-group">
      <div>
        <el-button
            v-for="button in config.buttons"
            :key="button.text"
            :type="button.type"
            :disabled="button.disabled ? button.disabled(context) : false"
            @click="() => button.handler(context)"
        >
          {{ button.text }}
        </el-button>
      </div>
      <div>
        <el-input
            v-model="searchKeyword"
            style="width: 240px"
            :placeholder="config.searchPlaceholder || '请输入关键字'"
        />
        <el-button type="primary" style="margin-left: 10px" @click="handleSearch">查询</el-button>
      </div>
    </div>

    <div class="table-container" ref="tableContainerRef">
      <el-table
          :data="tableData"
          border
          :height="tableHeight"
          @selection-change="handleSelectionChange"
          :row-class-name="rowClassName"
          style="width: max-content;"
      >
        <el-table-column type="index" label="" width="60" align="center" fixed />
        <el-table-column type="selection" width="55" v-if="config.selectable" fixed />
        <el-table-column
            v-for="column in config.columns"
            :key="column.prop"
            :prop="column.prop"
            :label="column.label"
            :width="column.width || 150"
        >
          <template #default="scope" v-if="column.formatter">
            {{ column.formatter(scope.row, column) }}
          </template>
        </el-table-column>

        <!-- 操作列 -->
        <el-table-column v-if="config.tableActions" :label="config.tableActions.columnName" :width="config.tableActions.width">
          <template #default="scope">
            <div>
              <template v-for="action in config.tableActions.buttons" :key="action.text">
                <!-- 如果是按钮类型 -->
                <el-button
                    v-if="action.componentType === 'button'"
                    :type="action.type"
                    :size="action.size"
                    @click="action.handler(scope.row, context)"
                >
                  {{ action.text }}
                </el-button>

                <!-- 如果是开关类型 -->
                <el-switch
                    v-if="action.componentType === 'switch'"
                    v-model="scope.row[action.prop]"
                    @change="action.handler(scope.row, context)"
                    :active-text="action.activeText"
                    :inactive-text="action.inactiveText"
                />
              </template>
            </div>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <div class="table-footer">
      <div class="data-info">
        选择 {{ selectedRows ? selectedRows.length : 0 }} / {{ tableData ? tableData.length : 0 }} 条数据
      </div>
    </div>

    <el-dialog
        v-if="config.dialog"
        :title="formData.id ? '编辑' : '新建'"
        v-model="dialogVisible"
        :width="config.dialog.width || '50%'"
        :before-close="handleDialogClose"
    >
      <el-form :model="formData" :rules="config.rules" label-width="80px">
        <el-row :gutter="20">
          <el-col v-for="field in config.dialog.fields" :key="field.prop" :span="field.span || 12">
            <el-form-item :label="field.label">
              <component
                  :is="field.type === 'select' ? 'el-select' : field.type === 'textarea' ? 'el-input' : field.type === 'date' ? 'el-date-picker' : 'el-input'"
                  v-model="formData[field.prop]"
                  :disabled="field.disabled"
                  v-if="field.type !== 'textarea' || field.type === 'textarea'"
                  :rows="field.rows || 4"
                  :type="field.type === 'textarea' ? 'textarea' : 'text'"
                  class="input-field"
              >
                <template v-if="field.type === 'select'" #default>
                  <el-option
                      v-for="option in field.options"
                      :key="option.value"
                      :label="option.label"
                      :value="option.value"
                  />
                </template>
              </component>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleDialogConfirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted, reactive } from 'vue';
import { useBaseDataOperations } from '@/composables/useBaseDataOperations';

const props = defineProps({
  config: Object,
  service: Object
});

const context = reactive({});
const tableContainerRef = ref(null);
const selectedRows = ref([]);
const selectedRowKeys = ref([]);

const {
  tableData,
  total,
  searchKeyword,
  formData,
  dialogVisible,
  fetchData,
  handleSearch,
  handleSizeChange,
  handleDelete,
    handleImport,
    handleExport,
  handleCurrentChange,
  handleDialogClose,
  handleDialogConfirm,
} = useBaseDataOperations(props.config, props.service);

const handleSelectionChange = (val) => {

  selectedRows.value = val;
  selectedRowKeys.value = val.map(item => item.id);
};

const rowClassName = ({ row }) => {
  return selectedRowKeys.value.includes(row.id) ? 'selected-row' : '';
};

Object.assign(context, {
  tableData,
  searchKeyword,
  selectedRows,
  formData,
  dialogVisible,
  fetchData,
  handleSearch,
  handleSelectionChange,
  handleSizeChange,
  handleCurrentChange,
  handleDelete,
  handleImport,
  handleExport,
  handleDialogClose,
  handleDialogConfirm,
  rowClassName
});

const tableHeight = ref(0);

const calculateTableHeight = () => {
  if (tableContainerRef.value) {
    tableHeight.value = tableContainerRef.value.clientHeight;
  }
};

onMounted(() => {
  fetchData();
  calculateTableHeight();
  window.addEventListener('resize', calculateTableHeight);
});

onUnmounted(() => {
  window.removeEventListener('resize', calculateTableHeight);
});

watch(searchKeyword, () => {
  if (searchKeyword.value === '') {
    fetchData();
  }
});
</script>

<style >
.base-data-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
}

.button-group {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EBEEF5;
}

.table-container {
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
}

.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border: 1px solid #EBEEF5;
}

.data-info {
  font-size: 14px;
  color: #606266;
}

.el-table {
  border: none;
}

/* 隐藏表格内部的滚动条 */
.el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}

/* 自定义底部滚动条样式 */
.table-container::-webkit-scrollbar {
  height: 8px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #909399;
  border-radius: 4px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #f5f7fa;
}

/* 提高选中行样式的优先级 */
.el-table__body tr.selected-row td {
  background-color: rgb(166, 210, 255) !important; /* 浅蓝色背景 */
}

/* 确保鼠标悬停时也保持选中的背景色 */
.el-table__body tr.selected-row:hover > td {
  background-color: rgb(166, 210, 255) !important; /* 悬停时也使用浅蓝色 */
}

/* 控制输入框的调整大小 */
.input-field {
  resize: none; /* 禁止调整大小 */
}

.input-field[type="textarea"] {
  resize: both; /* 允许 textarea 调整大小 */
  min-height: 100px; /* 可选：设置最小高度 */
}
</style>
