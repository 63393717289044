const Api = {};

export const loadApiModules = async () => {
    const context = require.context('./', false, /\.js$/);
    const modules = context.keys().filter(key => key !== './api.js'); // 排除 api.js 自己

    for (const key of modules) {
        const moduleName = key.replace('./', '').replace('.js', '');
        const module = await context(key);
        Api[moduleName] = {};

        for (const exportName in module) {
            if (Object.prototype.hasOwnProperty.call(module, exportName)) {
                Api[moduleName][exportName] = module[exportName];
            }
        }
    }
};

export default Api;
