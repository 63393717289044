import { createStore } from 'vuex';

export default createStore({
  state: {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    realName: localStorage.getItem('realName') || null,
    userType: localStorage.getItem('userType') || null,
    organization: localStorage.getItem('organization') || null,
    oid: localStorage.getItem('oid') || null
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token;
      localStorage.setItem('accessToken', token);  // 持久化到 localStorage
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
      localStorage.setItem('refreshToken', token);  // 持久化到 localStorage
    },
    setRealName(state, name) {
      state.realName = name;
      localStorage.setItem('realName', name);  // 持久化到 localStorage
    },
    setUserType(state, userType) {
      state.userType = userType;
      localStorage.setItem('userType', userType);  // 持久化到 localStorage
    },
    setOrganization(state, organization) {
      state.organization = organization;
      localStorage.setItem('organization', organization);  // 持久化到 localStorage
    },
    setOid(state, oid) {
      state.ord = oid;
      localStorage.setItem('oid', oid);  // 持久化到 localStorage
    },
    clearUserData(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.realName = null;
      state.userType = null;
      state.organization=null;
      state.oid=null;
     localStorage.clear();
    },
  },
  actions: {
    login({ commit }, userData) {
      commit('setAccessToken', userData.accessToken);
      commit('setRefreshToken', userData.refreshToken);
      commit('setRealName', userData.realName);
      commit('setUserType', userData.userType);
      commit('setOrganization', userData.organization);
      commit('setOid', userData.oid);
    },
    logout({ commit }) {
      commit('clearUserData');
    },
  },
  getters: {
    getOid(state) {
      return state.ord;  // 返回 oid
    },
  }

});
