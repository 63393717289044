// user.js

import request from '../utils/request';
import store from '../store'; // 引入store
// 登录函数
export const login = async ({ username, password }) => {
    try {
        const response = await request({
            method: 'POST',
            url: '/auth/weblogin',
            data: {
                username: username,
                password: password
            }
        });
        console.log("user.js==",response)

        const { accessToken, refreshToken, realName,userType,organization,oid } = response.data;
        // 方式1: // 登录成功，保存用户信息到 sessionStorage
        // sessionStorage.setItem('accessToken', accessToken);
        // sessionStorage.setItem('refreshToken', refreshToken);
        // 方式1:sessionStorage.setItem('realName', realName);
        localStorage.setItem('userInfo', JSON.stringify({ accessToken, refreshToken, realName,userType,organization,oid }));
        //将数据存到store
        store.dispatch('login', { accessToken, refreshToken, realName,userType,organization ,oid});

        return userType; // 返回登录成功标志
    } catch (error) {
        console.error('Login failed:', error);
        throw error; // 抛出错误，让调用者处理登录失败逻辑
    }
};

// 登出函数
export const logout = async () => {
    try {
         await request({
            method: 'POST',
            url: '/auth/logout'
        });
        // 方式1：登出成功，清除 sessionStorage 中的用户信息

        //方式2 登出成功，清除 Vuex store 中的用户信息
        store.dispatch('logout');

        return true; // 返回登出成功标志
    } catch (error) {
        console.error('Logout failed:', error);
        throw error; // 抛出错误，让调用者处理登出失败逻辑
    }
};
