
import request from '@/utils/request';

export default {
    addData(oid, data) {
        return request({
            url: '/company/add',
            method: 'post',
            data
        });
    },
    updateData(oid,data) {
        return request({
            url: '/company/update',
            method: 'post',
            data
        });
    },

    fetchData(oid) {
        return request({
            url: '/company/findRecentChangedData',
            method: 'get',
            params: { oid }
        });
    },

    deleteData(oid,ids) {
        return request({
            url: '/company/delete',
            method: 'delete',
            data: ids, // `ids` 作为请求体发送
        });
    },

    importData( oid,data) {
        return request({
            url: '/company/import',
            method: 'post',
            params: { oid },
            data
        });
    },

    searchData(oid, keyword) {
        return request({
            url: '/company/searchData',
            method: 'get',
            params: { oid,keyword }
        });
    }
};
